// @ts-nocheck
import React from "react"
import CustomTextField from "./CustomTextField.tsx"
import ApiConnect from "./ApiConnect.jsx"
const GetLogs = () => {
    const InitialRows = (Count) => { 
        const Rows = []
        for (let i = 0; i < Count; i++)
            Rows.push(
                <tr style={{minHeight: '20%'}} key={`initial-row-${i}`}>
                    <td></td> {/* ID */}
                    <td></td> {/* Nome */}
                    <td></td> {/* Cognome */}
                    <td></td> {/* Data */}
                    <td></td> {/* Firmato */}
                    <td></td> {/* Log */}
                </tr>
            )
        return Rows
    }
    const [Data,setData] = React.useState(InitialRows(5))
    const [Count,setCount] = React.useState([0,5])
    const TableRows = (Query) => {
        const Rows = Query.map((item) => {
            return(
                <tr key={item.Id}>
                    <td >{item.Id}</td>
                    <td>{item.Nome}</td>
                    <td>{item.Cognome}</td>
                    <td>{item.Date}</td>
                    <td>{item.Signed ? "sì" : "no"}</td>
                    <td></td>
                </tr>
            )
       })
       return Rows
    }

    const Page = (Direction) => {
        let arr = [];
        document.getElementById("pageButtonPrec").disabled = false;
        document.getElementById("pageButton").disabled = false;
        if (Direction){
            if (Count[1] + 5 < Data.length){
                arr[0] = Count[0] + 5; arr[1] = Count[1] + 5;
            }
            else if (Count[1] < Data.length) {
                arr[0] = Count[1] ; arr[1] = Data.length;
                document.getElementById("pageButton").disabled = true;
            }
            else{
                document.getElementById("pageButton").disabled = true;
                arr[0] = Count[0]; arr[1] = Count[1];
            }
        }
        else{
            if (Count[0] - 5 > 0){
                if (Count[1] % 5 !== 0)
                    arr[1] = Count[1] - Count[1] % 5;
                else 
                    arr[1] = Count[1] - 5;
                arr[0] = Count[0] - 5; 
            }
            else {
                document.getElementById("pageButtonPrec").disabled = true;
                arr[0] = 0; arr[1] = 5;
            }
        }
        setCount(arr)
    }

    const Search = () => {
        //@ts-ignore
        const Nome = document.getElementById("Nome").value
        //@ts-ignore
        const Cognome = document.getElementById("Cognome").value
        ApiConnect.get("/contractsLog", {
            headers: {
                "logintoken": localStorage.getItem("logintoken")
            },
            params:{
                Nome,
                Cognome
            }
        }).then((res) => {
            setCount([0,5])
            document.getElementById("pageButtonPrec").disabled = false;
            document.getElementById("pageButton").disabled = false;
            setData(TableRows(res.data))
        })
    }
    return (
        <div className="ColumnFlex" style={{ justifyContent: 'space-between', flexDirection: 'row', width: '100%' }}>
            <div className="Panel" id="LogPanelQuery">
                <div id="LogTitleQuery">
                    <h1 className="Title" style={{ margin: 0, fontSize: '29px' }}>Ricerca Documenti</h1>
                    <h2 style={{ marginTop: '0' }}>Ricerca per Nome e Cognome</h2>
                </div>
                <div className="ColumnFlex" style={{ rowGap: '10px', margin: '10px', height: 'auto' }}>
                    <CustomTextField label="Nome" id="Nome" />
                    <CustomTextField label="Cognome" id="Cognome" />
                </div>
                <button className={"btn"} style={{ width: '50%', margin: '0', marginTop: '10px' }} onClick={Search} >
                    <span style={{ fontSize: '30px' }} className={"Title"}>Cerca</span>
                </button>
            </div>
            <div className="ColumnFlex" id="TableDiv" style={{width:'60%'}}>
                <table id="LogTable" cellSpacing={0}>
                    <thead>
                        {/*TO FIX FOR WARNING*/}
                        <tr>
                            <td>ID</td>
                            <td>Nome</td>
                            <td>Cognome</td>
                            <td>Data</td>
                            <td>Firmato</td>
                            <td>Log</td>
                        </tr>
                    </thead>
                    <tbody>
                        {Data.slice(Count[0], Count[1])}
                    </tbody>
                </table>
                <button className={"btn"} id="pageButton" style={{ width: 'auto', margin: '0', marginTop: '10px' }} onClick={() => Page(1)} >
                    <span style={{ fontSize: '20px' }} className={"Title"}>Pg. Succ.</span>
                </button>
                <button className={"btn"} id="pageButtonPrec" style={{ width: 'auto', margin: '0', marginTop: '10px' }} onClick={() => Page(0)} >
                    <span style={{ fontSize: '20px' }} className={"Title"}>Pg. Prec.</span>
                </button>
                <span id="NrLog">Log Nr:{Data.length}</span>
            </div>
        </div>
    )
}
export default GetLogs