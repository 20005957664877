import { CheckListItem,HandleOneCheck } from "./Offerta"
import React from "react"
const FTTH = () => { 
    return (
        <CheckListItem
            FirstText={
                <>
                    <span style={{all: 'inherit'}}>Fibra Veloce</span>
                    <span className="SubTitleOfferta">Bmg fino a 10 mega in download e fino a 5 mega in upload
                    Comprende traffico dati illimitato gratuito</span>
                </>
            }
            SecondText={
                <>
                    <div className="FlexRow">
                        <span style={{all: 'inherit'}}>FTTH fino a 1 Gigabit/300 Mega 29.90€ mese</span>
                        <input type="checkbox" id="FTTH1Gigabit" onChange={(e)=>HandleOneCheck(e,["FTTH1Gigabit","FTTH25Gigabit"])} className="CheckOfferta"/>
                    </div>
                    <br/>
                    <div className="FlexRow" style={{marginTop: '0%'}}>
                        <span style={{all: 'inherit'}}>FTTH fino a 2.5 Gigabit/1 Gigabit 34.90€ mese</span>
                        <input type="checkbox" id="FTTH25Gigabit" onChange={(e)=>HandleOneCheck(e,["FTTH1Gigabit","FTTH25Gigabit"])} className="CheckOfferta"/>
                    </div>
                </>
            }
            hidden={true}
        />
    )
}

const FTTC = () =>{
    return (
        <CheckListItem
            FirstText={
                <>
                    <span style={{all: 'inherit'}}>Fibra Veloce</span>
                    <span className="SubTitleOfferta">(BMG dati 2 Mega - qualità aziendale: molto elevata)<br/>(FTTC fino a 200 Mega)</span>
                </>
            }
            SecondText="29.90€ mese"
            hidden={true}
        />
    )
}

const FTTHVOIP = () => {
    return (
        <CheckListItem
            FirstText={
                <>
                    <span style={{all: 'inherit'}}>Linea Voce + Fibra veloce</span>
                    <span className="SubTitleOfferta">Bmg fino a 10 mega in download e fino a 5 mega in upload
                    Comprende traffico dati illimitato gratuito</span>
                </>
            }
            SecondText={
                <>
                    <div className="FlexRow">
                        <span style={{all: 'inherit'}}>FTTH fino a 1 Gigabit/300 Mega 29.90€ mese</span>
                        <input type="checkbox" id="FTTH1Gigabit" onChange={(e)=>HandleOneCheck(e,["FTTH1Gigabit","FTTH25Gigabit"])} className="CheckOfferta"/>
                    </div>
                    <br/>
                    <div className="FlexRow" style={{marginTop: '0%'}}>
                        <span style={{all: 'inherit'}}>FTTH fino a 2.5 Gigabit/1 Gigabit 34.90€ mese</span>
                        <input type="checkbox" id="FTTH25Gigabit" onChange={(e)=>HandleOneCheck(e,["FTTH1Gigabit","FTTH25Gigabit"])} className="CheckOfferta"/>
                    </div>
                </>
            }
            hidden={true}
        />
    )
}


const FTTCVOIP = () => {
    return(
        <CheckListItem
            FirstText={
                <>
                    <span style={{all: 'inherit'}}>Linea Veloce + Fibra Veloce</span>
                    <span className="SubTitleOfferta">Fttc fino a 200 mega / 20 mega</span>
                </>
            }
            SecondText="30.90€ mese"
            hidden={true}
        />
    )
}

const VOIP = () => {
    return(
        <></>
    )
}

export {FTTH,FTTC,FTTHVOIP,FTTCVOIP,VOIP}