import ApiConnect from "./ApiConnect";
const ResReq = async(Text_Content,File) =>{
    const Data = new FormData()
    Data.append("Text_Content",JSON.stringify(Text_Content))  
    Data.append("File0",File[0])     
    Data.append("File1",File[1])      
    await (Data.get("Text_Content"))                   
    const res = await ApiConnect.post("/reseller/SendData",
        Data,{
        headers: {
            "logintoken": localStorage.getItem("logintoken"),
            "Content-Type": "multipart/form-data",
        }
    })
    if(res.data.AuthError)
        return {AuthError: true}
    return {AuthError: false}
}
export default ResReq;