import { useNavigate } from "react-router-dom"
import React from "react"
const LogOut = ({setStastus}) => {
    const navigate = useNavigate()
    const LogOutFunction = () => {
        localStorage.removeItem("logintoken");
        navigate("/Login",{replace: true})
        setStastus({
            status: false,
            Role: ""
        })
    }
    return (
        <button onClick={LogOutFunction}  className="btn" id="LogOut">
            <span className="Title" style={{fontSize: '30px'}} >Log-Out</span>
        </button>
    )
}
export default LogOut