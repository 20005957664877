import React from "react"
import { useNavigate } from "react-router-dom"
import DownloadIcon from "./Media/DownloadIcon.png"
import ApiConnect from "./ApiConnect"
import { saveAs } from "file-saver"
import JSZip from "jszip"
import { useState } from "react"

const DownloadButton = (func) => {
    return(
        <button className="DownloadButton" onClick={func} >
            <img src={DownloadIcon} alt="DownloadIcon" style={{height: '48px', width: '48px'}}/>
        </button>
    )
}


const Landing = () => {
    const navigate = useNavigate()
    const [Offer,setOffer] = useState([])
    const [App,setApp] = useState(false)
    const GetOfferSigned = () => {
        ApiConnect.get("/form/GetSignedOffer",{
            headers: {
                'x-access-token':  localStorage.getItem("SMSSession"),
            },
            responseType: 'arraybuffer',        
        }).then((response) => {
            if (response.status === 204){
                return
            }
            JSZip.loadAsync(response.data).then((zip) => {
                const arr = []
                if (Object.keys(zip.files).length === 1)
                    setApp(false)
                else
                    setApp(true)
                zip.forEach((relativePath, zipEntry) => {
                    zipEntry.async("blob").then((blob) => {
                        arr.push({name: zipEntry.name, blob: blob})
                    })
                })
                setOffer(arr)
            }
        )
        })
    }
    const GetContrattoSigned = () => {
        ApiConnect.get("/form/GetSignedContract",{
            responseType: 'arraybuffer',
            headers: {
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json',
                "x-access-token": localStorage.getItem("SMSSession"),
                
            }
        })
        .then((res)=>{
            if (res.data === "No Result Found"){
                return
            }
            saveAs(new Blob([res.data]),"Contratto.pdf")
        })
    }

    React.useEffect(() => {
        //spostare preferibilmente
        if (!localStorage.getItem("Session"))
            navigate("/",{replace: true}) 
        GetOfferSigned()
    },[navigate])    
    return(
        <div className="basicFlexColumn">
            <h2>Token Corretto</h2>
                <div className="basicFlexColumn" style={{flexDirection: 'column'}}>
                    <div className="Panel" style={{width: '40%',padding: '1%'}}>
                        Scarica i documenti e conservali, riceverai anche un e-mail con i seguenti allegati.
                        <div className="rowContainer" style={{justifyContent: 'center',alignItems: 'center',columnGap: '20px'}}>
                            <div className="rowContainer" style={{justifyContent: 'center',alignItems: 'center',columnGap: '20px'}}>
                                {DownloadButton(()=> saveAs(Offer[0].blob,Offer[0].name))}
                                <span style={{fontSize: '1.3rem',lineHeight: '1.66', letterSpacing: '0.0333em'}}>Offerta.pdf</span>
                            </div>
                            {App &&
                                <div className="rowContainer" style={{justifyContent: 'center',alignItems: 'center',columnGap: '20px'}}>
                                    {DownloadButton(()=> saveAs(Offer[1].blob,Offer[1].name))}
                                    <span style={{fontSize: '1.3rem',lineHeight: '1.66', letterSpacing: '0.0333em'}}>Quotazione.pdf</span>
                                </div>
                            }
                        </div>
                        <div className="rowContainer" style={{justifyContent: 'center',alignItems: 'center',columnGap: '20px'}}>
                            {DownloadButton(()=> GetContrattoSigned())}
                            <span style={{fontSize: '1.3rem',lineHeight: '1.66', letterSpacing: '0.0333em'}}>ContrattoFirmatoTLC.pdf</span>
                        </div>
                    </div>
                    <p className="SimplePar" style={{width: '40%', fontSize: '0.67rem',margin: '0px 0px 20px 0px'}}>
                            Trovi una copia di tutti i documenti contrattuali con le tue firme nella tua casella e-mail, qualora non dovessi ricevere il documento puoi scrivere a: <a href="mailto:info@tlc.it" style={{textDecoration: 'none',color: '#F3440E'}}>info@tlc.it</a>. Conserva i documenti! Ricorda che il contratto e i tuoi dati sono sottoposti alle verifiche di TLC, pertanto, ti manderemo una e-mail indicandoti i tempi di attivazione o indicandoti i motivi per cui non possiamo dare seguito alla tua richiesta.  
                            <span style={{textDecoration: 'underline'}}>
                                Il contratto firmato del Cliente non costituisce accettazione di TLC.
                            </span>
                        </p>
                </div>    

                <div style={{width: '50%',margin: '20px', display:'flex',alignItems: 'center',justifyContent: 'center'}}>
                    <button className="btn" onClick={()=>navigate("/",{replace: true})}><span className="Title" style={{fontSize: '30px'}}>Indietro</span></button>
                </div>
        </div>
    )
}
export default Landing