import React from "react"
import "./Css/Offerta.css"
import { MenuItem, Select, TextField } from "@mui/material"
import MuiStyles from "./MuiStyles"
import PropTypes from 'prop-types';
import Tariffe from "./Tariffe";
import Offerta_Voip_Bus from "./Offerta_Voip_Bus";
import { FTTC, FTTH, FTTCVOIP, FTTHVOIP } from "./PianiOfferta";
import { Offerta_Anag, Offerta_Voip_Res } from "./Offerta_Voip_Res";
const HandleOneCheck = (e, Ids) => {
    Ids.forEach((id) => {
        if (id === e.target.id) return
        const element = document.getElementById(id)
        element["checked"] = false;
    })
}

const CheckListItem = ({ FirstText, SecondText, onChange, checked, id, hidden }) => {
    if (hidden === undefined) hidden = false
    return (
        <>
            <div className="BoxText">
                <span className="FirstText">
                    {FirstText}
                </span>
            </div>
            <div className="BoxText">
                <span>
                    {SecondText}
                </span>
            </div>
            <div className="BoxText">
                <input
                    type="checkbox"
                    onChange={onChange ?? (() => { })}
                    className="CheckOfferta"
                    checked={checked ?? null}
                    id={id}
                    style={{ visibility: hidden ? "hidden" : "visible" }}
                />
            </div>


        </>
    )
}

CheckListItem.propTypes = {
    FirstText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    SecondText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    hidden: PropTypes.bool
};

const Offerta = ({ pianoLinea, isBusiness }) => {
    const [piano, setPiano] = React.useState(
        FTTCVOIP
    )
    const [IpPubblico, setIpPubblico] = React.useState("0")
    const [Telefono, setTelefono] = React.useState(true)
    const [OnlyVoip, setOnlyVoip] = React.useState(false)
    React.useEffect(() => {
        setOnlyVoip(false)
        if (!pianoLinea) return
        setTelefono(pianoLinea[1] === "2" || pianoLinea[1] === "4")
        if ((pianoLinea[0] === "FTTH" && (pianoLinea[1] === "0" || pianoLinea[1] === "3")))
            setPiano(FTTH)
        else if ((pianoLinea[0] === "FTTC" && (pianoLinea[1] === "0" || pianoLinea[1] === "3")))
            setPiano(FTTC)
        else if ((pianoLinea[0] === "FTTH" && (pianoLinea[1] === "2" || pianoLinea[1] === "4")))
            setPiano(FTTHVOIP)
        else if (pianoLinea[0] === "VOCE")
            setOnlyVoip(true)
    }, [])

    const [Premium, setPremium] = React.useState(false)
    const ApparatoIds = ["Acquisto", "AcquistoRate", "Noleggio", "NoApparato"]
    const [Rate, setRate] = React.useState(false)

    const handleChangeCheck = (e, setStatus) => {
        setStatus(e.target.checked)
    }
    if (OnlyVoip)
        return (
            <div className="basicFlexColumn" style={{ textAlign: 'center', width: '50%', alignItems: 'flex-start' }}>
                <h3 style={{ width: '100%' }}>Offerta Da Attivare</h3>
                <div className="GridOffertaMain" style={{ width: 'calc(80% * 0.6)', minWidth: '100%', borderWidth: '2px', borderColor: 'var(--orange' }}>
                    {
                        isBusiness ?
                            <Offerta_Voip_Bus /> :
                            <Offerta_Voip_Res />
                    }
                </div>
                <h3 style={{ width: '100%' }}>Dati Attivazione/Migrazione</h3>
                <Offerta_Anag Migrazione={Number(pianoLinea[1] == 5)} />
            </div>
        )
    return (
        <div className="basicFlexColumn" style={{ width: '50%', textAlign: 'center' }}>
            <h3>Offerta Da Attivare</h3>
            <div className="GridOffertaMain" style={{ width: '100%', borderWidth: '2px', borderColor: 'var(--orange' }}>
                {piano}
                <CheckListItem
                    FirstText="Backup su Sim 4G LTE: 65 Giga mese "
                    SecondText={<span style={{ all: 'inherit' }}>29.90 € mese<br />Attivazione: 50.00 € Una Tantum </span>}
                    id="BackupSIM"
                />
                <CheckListItem
                    FirstText="LTE Backup IP Continuity: 10 Giga mese"
                    SecondText={<span style={{ all: 'inherit' }}>69.90 € mese<br />Attivazione: 50.00 € Una Tantum </span>}
                    id="BackupIP"
                />
                <CheckListItem
                    FirstText="Velocità di navigazione e Banda garantita"
                    SecondText="GRATIS"
                    id={null}
                    hidden={true}
                />
                <CheckListItem
                    FirstText=
                    {
                        <>
                            <span style={{ all: 'inherit' }}>PRIORITARIZZAZIONE pacchetti<br /> per operazione in tempo reale</span>
                            <span className="SubTitleOfferta">(fatturazione elettronica, gestione mail, server, ecc…)</span>
                        </>
                    }
                    SecondText="GRATIS"
                    id={null}
                    hidden={true}
                />
                {Telefono &&
                    <>
                        <CheckListItem
                            FirstText={
                                <>
                                    <span style={{ all: 'inherit' }}>Servizio Chi è</span>
                                    <span className="SubTitleOfferta">(Vedi chi ti chiama)</span>
                                </>
                            }
                            SecondText="GRATIS"
                            id={null}
                            hidden={true}
                        />
                        <CheckListItem
                            FirstText={
                                <>
                                    <span style={{ all: 'inherit' }}>Pacchetto Premium:</span>
                                    <span className="SubTitleOfferta">Segreteria telefonica, Notifica chiamate ricevute mezzo mail in tempo reale,<br /> Backup della fonia su
                                        un numero di rete mobile in caso di guasto,<br /> Servizio trasferimento di chiamata</span>
                                </>
                            }
                            SecondText={
                                <>
                                    <span style={{ all: 'inherit' }}>2.00€</span>
                                    {Premium &&
                                        <>
                                            <span id="NotificheText" className="SubTitleOfferta">Indicare Mail di gestione notifiche:</span>
                                            <TextField id="Notifiche" sx={{ ...MuiStyles.TextField }} />
                                        </>
                                    }
                                </>
                            }
                            id="Premium"
                            onChange={(e) => handleChangeCheck(e, setPremium)}
                            checked={Premium}
                        />
                        <CheckListItem
                            FirstText={
                                <>
                                    <span style={{ all: 'inherit' }}>Possibilità di fare o ricevere fino a 2 chiamate contemporanee</span>
                                </>
                            }
                            SecondText="5€ mese"
                            id="SecondoNumero"
                        />
                        <CheckListItem
                            FirstText={
                                <>
                                    <span style={{ all: 'inherit' }}>Vuoi un secondo numero?</span>
                                    <span className="SubTitleOfferta">con 2 numeri è possibile fare 2 o ricevere 2 chiamate contemporanee, una per numero</span>
                                </>
                            }
                            SecondText={
                                <>
                                    <span style={{ all: 'inherit' }}>10€ mese<br />Attivazione: 20€ una tantum </span>
                                </>
                            }
                            id="Attivazione"
                        />
                    </>
                }
                <CheckListItem
                    FirstText={
                        <>
                            <span style={{ all: 'inherit' }}>Richiesta IP pubblico statico</span>
                            <span style={{ all: 'inherit' }}>
                                Indicare il numero di IP statici da attivare:
                                <Select
                                    id="IpPubblico"
                                    defaultValue={IpPubblico}
                                    value={IpPubblico}
                                    inputProps={{ id: "IpPubblicoInput" }}
                                    onChange={(e) => setIpPubblico(e.target.value)}
                                    sx={{ ...MuiStyles.Select, margin: '0 10px' }}
                                >
                                    <MenuItem value="0">0</MenuItem>
                                    <MenuItem value="1">1</MenuItem>
                                    <MenuItem value="8">8</MenuItem>
                                    <MenuItem value="16">16</MenuItem>
                                </Select>
                            </span>
                            <span className="SubTitleOfferta">(obbligatorio per chi associa alla connettività un centralino)</span>
                        </>
                    }
                    SecondText={
                        <>
                            <span style={{ all: 'inherit' }}>{Number(IpPubblico) * 4}€ mese</span>
                            <span style={{ all: 'inherit' }}>Attivazione: {IpPubblico === "0" ? 0 : ((Number(IpPubblico) - 1) * 10) + 30}€ una tantum</span>
                            <span className="SubTitleOfferta">(4€ mese per IP statico) <br />(attivazione 30 € per il primo IP + 10 € per ciascuno dei successivi)</span>

                        </>
                    }
                    id="IpPubblico"
                    hidden={true}
                />
                <CheckListItem
                    FirstText={"Costo Attivazione del servizio"}
                    SecondText={
                        <>
                            <div className="FlexRow">
                                <span style={{ all: 'inherit' }}>Una tantum 99.90€</span>
                                <input type="checkbox" id="AttivazioneTantum" onChange={(e) => HandleOneCheck(e, ["AttivazioneTantum", "AttivazioneRate"])} className="CheckOfferta" />
                            </div>
                            <br />
                            <div className="FlexRow" style={{ marginTop: '0%' }}>
                                <span style={{ all: 'inherit' }}>Comodo 4.20€(24 mesi)</span>
                                <input type="checkbox" id="AttivazioneRate" onChange={(e) => HandleOneCheck(e, ["AttivazioneTantum", "AttivazioneRate"])} className="CheckOfferta" />
                            </div>
                        </>
                    }
                    id="Attivazione"
                    hidden={true}
                />
                <CheckListItem
                    FirstText={
                        <>
                            <span style={{ all: 'inherit' }}>Costo Migrazione del servizio</span>
                            <span className="SubTitleOfferta">(Il contributo vale solo per le migrazioni tra pari tecnologie)</span>
                        </>}
                    SecondText={"Una tantum 10.00€"}
                    id="Migrazione"
                    hidden={true}
                />
            </div>

            {Telefono &&
                <>
                    <h3>Opzioni Tariffarie Disponibili</h3>
                    <div className="GridOffertaMain2" style={{ width: '100%', borderWidth: '2px', borderColor: 'var(--orange' }}>
                        <Tariffe />
                    </div>
                </>
            }
            <h3>Offerta Apparato TLC Box</h3>
            <p style={{ width: '35%', margin: '0' }}>Router AVM1 con 4 porte Ethernet
                WIFi n 450 (WiFi 4 volte più potente dei normali router)
                Dotato di 2 porte analogiche per il collegamento di 2
                normali telefoni
            </p>
            <div className="GridOffertaMain2" style={{ width: '100%', borderWidth: '2px', borderColor: 'var(--orange' }}>
                <CheckListItem
                    FirstText="Acquisto Apparato"
                    SecondText={<span>
                        249.90€ <br />
                    </span>}
                    id="Acquisto"
                    onChange={(e) => { HandleOneCheck(e, ApparatoIds); setRate(false) }}
                />
                <CheckListItem
                    FirstText={
                        <>
                            <span style={{ all: 'inherit' }}>Acquisto Apparato a rate</span>
                        </>
                    }
                    SecondText={
                        <>
                            <span>
                                6 rate a 42.00€ <br />
                            </span>
                        </>
                    }
                    id="AcquistoRate"
                    onChange={(e) => { HandleOneCheck(e, ApparatoIds); setRate(false) }}
                />
                <CheckListItem
                    FirstText="Noleggio Apparato"
                    SecondText={
                        <>
                            <span>
                                6.00€ mese <br />
                            </span>
                        </>
                    }
                    id="Noleggio"
                    onChange={(e) => { HandleOneCheck(e, ApparatoIds); setRate(e.target.checked) }}
                />
                <CheckListItem
                    FirstText={
                        <>
                            <span>No<br /></span>
                            <span className="SubTitleOfferta">
                                Il cliente si assume la responabilità:<br />
                                di configurare autonomamente l'apparato
                            </span>
                        </>
                    }
                    SecondText="0.00€"
                    id="NoApparato"
                    onChange={(e) => { HandleOneCheck(e, ApparatoIds); setRate(false) }}
                />
            </div>
            {Rate && <>
                <h3>Sostituzione apparato</h3>
                <p style={{ width: '35%', margin: '0' }}>In caso di fulminazioni o cadute accidentali</p>
                <div className="GridOffertaMain2" style={{ width: 'calc(80% * 0.6)', borderWidth: '2px', borderColor: 'var(--orange' }}>
                    <CheckListItem
                        FirstText={
                            <>
                                <span>Sì<br /></span>
                            </>
                        }
                        SecondText="0.99€ mese"
                        id="SiSostituzione"
                        onChange={(e) => HandleOneCheck(e, ["SiSostituzione", "NoSostituzione"])}
                    />
                    <CheckListItem
                        FirstText="No"
                        SecondText="0.00€"
                        id="NoSostituzione"
                        onChange={(e) => HandleOneCheck(e, ["SiSostituzione", "NoSostituzione"])}
                    />
                </div>
            </>
            }
            <span className="SubTitleOfferta" style={{ margin: 0 }} >In caso di Acquisto/Noleggio dell'apparato verranno aggiunti 15.00€ per coprire le spese di spedizione e la configurazione</span>
        </div>
    )

}

export default Offerta
export { CheckListItem }
export { HandleOneCheck }
