import React from "react"
import { useReducer } from "react"
const CheckDevEnv = () => {
    return process.env.NODE_ENV === "development"
}
const ChangeContext = (State,setState) => {
    if (!CheckDevEnv())
        return null
    const change = () => {
        let OldState = State
        // @ts-ignore
        const text = document.getElementById("ChangeContext").value
        const arr = text.split(":")
        OldState[arr[0]] = arr[1]
        (OldState)
        setState(OldState)
    }
    return(
        <div style={{display: 'flex'}}>
            <input type="Text" id="ChangeContext" placeholder="Change Context" style={{width: '100%'}}/>
            <button onClick={change} style={{width: '100%'}}>Change</button>
        </div>
    )
}
const ForceRerender = () => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    if (!CheckDevEnv())
        return null
    return <button onClick={forceUpdate}>Forza Aggiornamento</button>;
}
export {ChangeContext,ForceRerender}