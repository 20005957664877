import React from "react"
import { TextField } from "@mui/material"
import MuiStyles from "./MuiStyles"
import "./Css/Login.css"
import Warning from "./Warning"
import ApiConnect from "./ApiConnect"
import { useNavigate } from "react-router-dom"

const fetch = async() => {
    return await ApiConnect.post("/auth/validate",{},{headers: {"logintoken" : localStorage.getItem("logintoken")}})
}

const Login = ({setStatus}) => {
    const [Error,SetError] = React.useState("")
    const navigate = useNavigate()
    const [Sucess,setSucess] = React.useState(false)
    const LoginReq = async() =>{
        // @ts-ignore
        const username = document.getElementById("UserName").value
        // @ts-ignore
        const password = document.getElementById("password").value
        if (username === "" || password === ""){
            SetError("Inserire tutti i campi")
            // @ts-ignore
            document.getElementById("Basic").showModal()
            return
        }
        const res = await ApiConnect.post("/auth/login",{
            username,
            password
        })
        if (res.data.status){
            localStorage.setItem("logintoken",res.data.token)
            fetch().then(
                res => {
                    setStatus(res.data)
                }
            ).finally(() => setSucess(true))
            return   
        }
        SetError(res.data.message)
        // @ts-ignore
        document.getElementById("Basic").showModal()            
    }
    if (Sucess){
        navigate("/ResellerForm",{replace: true})
        return <></>
    }
    return (
        <div className="ColumnFlex">
            <Warning  title={<span className="titledialog">Attenzione!</span>} id="Basic">
                <div className="desc">
                    <span style={{fontSize: '20px', padding: '10px'}}>{Error}</span>
                </div>
            </Warning>
            <div className="Panel" style={{width: '40%', height: '75%', rowGap: '20px'}}>
                <div>
                    <h1 className="Title" style={{margin: 0}} >Login</h1>
                    <h2 style={{marginTop: '0'}}>Area Reseller</h2>
                </div>
                <div style={{width: '70%'}}>
                    <TextField sx={{...MuiStyles.TextField}} label="UserName" fullWidth name="UserName" id="UserName"/>
                </div>
                <div style={{width: '70%'}}>
                    <TextField sx={{...MuiStyles.TextField}} type="password" label="Password" fullWidth name="password" id="password"/> 
                </div>
                <button className={"btn"} style={{width: '50%', marginBottom: "20px", marginTop: '20px'}} onClick={LoginReq} ><span style={{fontSize: '30px'}} className={"Title"}>Accedi</span></button>
                </div>
        </div>
    )
}
const obj = {Login,fetch}
export default obj