import React from "react"
import { CheckListItem } from "./Offerta"
import { Select, MenuItem } from "@mui/material"
import MuiStyles from "./MuiStyles"
const Offerta_Voip_Bus = () => {
    const ServizioChiamata = [
        "1 Trunk VOIP 3 canali",
        "1 Trunk VOIP 6 canali",
        "1 Trunk VOIP 10 canali",
        "1 Trunk VOIP 20 canali",
        "1 Trunk VOIP 30 canali",
        "1 Trunk VOIP 50 canali",
    ]
    const CostiServizioChiamata = [
        {
            mensile: "3.90 € mese PER SEMPRE",
            tantum: "30 € Una tantum"
        },
        {
            mensile: "14.90 € mese PER SEMPRE ",
            tantum: "30 € Una tantum"
        },
        {
            mensile: "24.90 € mese PER SEMPRE",
            tantum: "50 € Una tantum"
        },
        {
            mensile: "44.90 € mese PER SEMPRE",
            tantum: "50 € Una tantum"
        },
        {
            mensile: "69.90 € mese PER SEMPRE",
            tantum: "50 € Una tantum"
        },
        {
            mensile: "99.90 € mese PER SEMPRE",
            tantum: "50 € Una tantum"
        },
    ]

    const Gnr = [
        "GNR 10 – chiamate contemporanee 3",
        "GNR 100 – chiamate contemporanee 15",
        "GNR 1000 – chiamate contemporanee 30"
    ]

    const GnrCosti = [
        {
            mensile: "29.90 € mese PER SEMPRE",
            tantum: "100 € Una tantum"
        },
        {
            mensile: "79.90 € mese PER SEMPRE",
            tantum: "100 € Una tantum"
        },
        {
            mensile: "249.90 € mese PER SEMPREs",
            tantum: "200 € Una tantum"
        }
    ]
    const [ServizioGnrState, setServizioGnrState] = React.useState(0)
    const [ServizioChiamataState, setServizioChiamataState] = React.useState(0)
    return(
        <>
            <CheckListItem
                FirstText={
                    <>
                        <span style={{all: 'inherit'}}>Tipologia di Servizio/Chiamata</span>
                        <span style={{all: 'inherit',padding: '3%'}}>
                            <Select
                                id="ServizioChiamata"
                                defaultValue={ServizioChiamataState}
                                value={ServizioChiamataState}
                                inputProps={{id: "ServizioChiamataInput"}}
                                // @ts-ignore
                                onChange={(e) => setServizioChiamataState(e.target.value)}
                                sx={{...MuiStyles.Select,margin: '0 10px',padding: '0 10px'}}
                            >
                            {
                                ServizioChiamata.map((item, index) => (
                                    <MenuItem id={item} key={index} value={index}>{item}</MenuItem>
                                ))
                            }
                            </Select>
                        </span>
                    </>
                }
                SecondText={
                    <>
                        <span style={{all: 'inherit'}}>{CostiServizioChiamata[ServizioChiamataState]?.mensile}</span>
                        <span style={{all: 'inherit'}}>Attivazione: {CostiServizioChiamata[ServizioChiamataState]?.tantum}</span>
                    </>
                }
                id="ServizioChiamata"
                hidden={true}
            />
            <CheckListItem 
                FirstText=
                {
                    <>
                        <span style={{all: 'inherit'}}>Tipologia di Servizio/Chiamata</span>
                        <span className="SubTitleOfferta">Da 51 a 1000 canali</span>
                    </>
                }
                SecondText="A Progetto"
                id={null}
                hidden={true}
            />
            <CheckListItem 
                FirstText=
                {
                    <>
                        <span style={{all: 'inherit'}}>Archi di numerazioni successive</span>
                    </>
                }
                SecondText="A Progetto"
                id={null}
                hidden={true}
            />
            <CheckListItem
                FirstText={
                    <>
                        <span style={{all: 'inherit'}}>Tipologia di Servizio GNR</span>
                        <span style={{all: 'inherit',padding: '3%'}}>
                            <Select
                                id="ServizioGnr"
                                defaultValue={ServizioGnrState}
                                value={ServizioGnrState}
                                inputProps={{id: "ServizioGnrInput"}}
                                // @ts-ignore
                                onChange={(e) => setServizioGnrState(e.target.value)}
                                sx={{...MuiStyles.Select,margin: '0 10px'}}
                            >
                            {
                                Gnr.map((item, index) => (
                                    <MenuItem key={index} value={index}>{item}</MenuItem>
                                ))
                            }
                            </Select>
                        </span>
                    </>
                }
                SecondText={
                    <>
                        <span style={{all: 'inherit'}}>{GnrCosti[ServizioGnrState]?.mensile}</span>
                        <span style={{all: 'inherit'}}>Attivazione: {GnrCosti[ServizioGnrState]?.tantum}</span>
                    </>
                }
                id="GNR"
                hidden={true}
            />
            <CheckListItem 
                FirstText=
                {
                    <>
                        <span style={{all: 'inherit'}}>Chiamate Supplementari su GNR</span>
                    </>
                }
                SecondText="A Progetto"
                id={null}
                hidden={true}
            />
            <CheckListItem 
                FirstText=
                {
                    <>
                        <span style={{all: 'inherit'}}>Urbane – Interurbane – Cellulari</span>
                    </>
                }
                SecondText="0.0498 € al minuto"
                id={null}
                hidden={true}
            />
            <CheckListItem
                FirstText={
                    <>
                        <span style={{all: 'inherit'}}>
                            Flat Italia Fissi e Mobili                          
                        </span>
                        <span className="SubTitleOfferta">
                            Chiamate locali e nazionali e verso cellulari illimitate*<br/> GRATUITE
                            senza scatto alla risposta<br/>                            
                        </span>
                        <span className="SubTitleOfferta" style={{fontSize: '0.6em'}}>
                        (*tetto mensile di 900 minuti suddivisi in 300 minuti di urbane, 300 di interurbane e 300 verso cellulari
                            nazionali. Chiamate oltre plafond a 0.0498 € al minuto senza scatto alla risposta)
                        </span>
                    </>
                }
                SecondText = "6.00 € mese"
                id="Tariffa2"
            />
            <CheckListItem 
                FirstText=
                {
                    <>
                        <span style={{all: 'inherit'}}>Scatto alla risposta</span>
                    </>
                }
                SecondText="GRATIS"
                id={null}
                hidden={true}
            />
            <CheckListItem 
                FirstText=
                {
                    <>
                        <span style={{all: 'inherit'}}>Estero</span>
                    </>
                }
                SecondText="Personalizzabile in base alle Vs. esigenze"
                id={null}
                hidden={true}
            />
        </>
    )
}
export default Offerta_Voip_Bus