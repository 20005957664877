import React from "react"
import MuiStyles from "./MuiStyles"
import { TextField } from "@mui/material"
import { CheckListItem } from "./Offerta"
const Offerta_Voip_Res = () => {
    return(
        <>
            <CheckListItem 
                FirstText=
                {
                    <>
                        <span style={{all: 'inherit'}}>Tipologia di Servizio/Chiamata</span>
                        <span className="SubTitleOfferta">1 Linea Voce</span>
                    </>
                }
                SecondText= 
                {
                    <>
                        <span style={{all: 'inherit'}}>3.90 € mese PER SEMPRE</span>
                        <span style={{all: 'inherit'}}>30 € Una tantum</span>
                    </>
                }
                id={null}
                hidden={true}
            />
            <CheckListItem 
                FirstText=
                {
                    <>
                        <span style={{all: 'inherit'}}>Chiamate Urbane – Interurbane – Cellulari </span>      
                    </>              
                }
                SecondText= "0.0498 € al minuto "
                id={null}
                hidden={true}
            />
            <CheckListItem 
                FirstText=
                {
                    <>
                        <span style={{all: 'inherit'}}>Flat Italia Fissi e Mobil</span>
                        <span className="SubTitleOfferta">*tetto mensile di 900 minuti suddivisi in: 300 minuti di urbane, <br/>300 di interurbane e 300 verso cellulari nazionali.<br/> Chiamate oltre plafond a 0.0498 € al minuto senza scatto alla risposta </span>
                    </>
                }
                SecondText="900 Minuti: € 6.00"
                id={"FLAT_BUS"}
            />
            <CheckListItem 
                FirstText=
                {
                    <>
                        <span style={{all: 'inherit'}}>Scatto alla risposta</span>
                    </>
                }
                SecondText="GRATIS"
                id={null}
                hidden={true}
            />
            <CheckListItem 
                FirstText=
                {
                    <>
                        <span style={{all: 'inherit'}}>Estero</span>
                    </>
                }
                SecondText="Personalizzabile in base alle Vs. esigenze"
                id={null}
                hidden={true}
            />
        </>
    )
}

const Offerta_Anag = ({Migrazione}) => {
    if (!Migrazione) Migrazione = false
    console.log(Migrazione)
    return(
        <div  className="FlexDati" style={{alignItems: 'center'}}>
            
            <div className="basicFlexColumn" style={{margin: '10px',width: '95%'}}>
                <span style={{fontSize: '18px',fontWeight: 'bold',textAlign: 'left',marginLeft: '5%', width:'100%'}}>Indirizzo di Attestazione</span>
                <div className="rowContainer">
                    <div id="Comune_Offerta">
                        <TextField sx={{
                            ...MuiStyles.TextField
                        }}  label="Comune" variant="outlined" name="Comune_Offerta" fullWidth/>
                    </div>
                    <TextField sx={{
                        ...MuiStyles.TextField
                    }}  label="Indirizzo" variant="outlined" name="Indirizzo_Offerta" fullWidth/>
                    <div id="Civico_Offerta">
                        <TextField sx={{
                            ...MuiStyles.TextField
                        }}  label="Civico" variant="outlined" name="Civico_Offerta" fullWidth/>
                    </div>
                    <div id="CAP_Offerta">
                        <TextField sx={{
                            ...MuiStyles.TextField
                        }}  label="CAP" variant="outlined" name="CAP_Offerta" fullWidth/>
                    </div>
                    <div id="PROV_Offerta">
                        <TextField sx={{
                            ...MuiStyles.TextField
                        }}  label="Prov." variant="outlined" name="PROV_Offerta" fullWidth/>
                    </div>
                </div>
                <div className="rowContainer" style={{width:'100%',justifyContent:'left'}}>
                    <div id="Prefisso_Offerta">
                        <span style={{fontSize: '18px',fontWeight: 'bold',textAlign: 'left',marginLeft: '5%'}}>Prefisso</span>
                        <TextField sx={{
                            ...MuiStyles.TextField
                        }}  label="Prefisso" variant="outlined" name="Prefisso_Offerta" fullWidth/>
                    </div>
                    {
                        Migrazione && 
                        <div id="Migrazione_Offerta">
                            <span style={{fontSize: '18px',fontWeight: 'bold',textAlign: 'left',marginLeft: '5%'}}>Dati Migrazione</span>
                            <div className="rowContainer" style={{width:'100%',justifyContent:'left'}}>
                                <TextField sx={{
                                    ...MuiStyles.TextField
                                }}  label="Numero" variant="outlined" name="Numero_Offerta"/>
                                <TextField sx={{
                                    ...MuiStyles.TextField
                                }}  label="Codice migrazione" variant="outlined" name="Codice_migrazione"/>
                            </div>
                            <TextField sx={{
                                ...MuiStyles.TextField
                            }}  label="Operatore di provenienza" variant="outlined" name="Operatore_Offerta"/>
                        </div>
                    }
                    
                </div>

            </div>
        </div>
    )
}
export default Offerta_Voip_Res
export {Offerta_Voip_Res,Offerta_Anag}
