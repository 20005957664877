import React, { useEffect } from 'react';
import Warning from './Warning';
import { TextField } from '@mui/material';
import MuiStyles from './MuiStyles';
import './Css/Login.css';
import ApiConnect from './ApiConnect';
import { Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import deleteMedia from "./Media/delete.png"

const Register = () => {
    const [checks,setChecks] = React.useState([])
    const navigate = useNavigate()
    const [Role,setRole] = React.useState("user")   
    const [Data,setData] = React.useState([])
    // @ts-ignore
    const  [Error,setError] = React.useState("")
    // @ts-ignore
    const fetchTable = () => {
        ApiConnect.get("/reseller/GetReseller",{
            headers: {
                "logintoken": localStorage.getItem("logintoken")
            }
        }).then((res) =>{
            if (res.data.AuthError)
                return navigate("/Login",{replace: true})
            setDataTable(res.data)
        })
    } 
    
    useEffect(() => {
        ("fetching")
        const fetchTable = () => {
            ApiConnect.get("/reseller/GetReseller",{
                headers: {
                    "logintoken": localStorage.getItem("logintoken")
                }
            }).then((res) =>{
                if (res.data.AuthError)
                    return navigate("/Login",{replace: true})
                setDataTable(res.data)
            })
        }
        fetchTable()
        // eslint-disable-next-line
    },[])
    const UpdateChecks = (e) => {
        if (e.target.checked)
        {
            if (checks.includes(e.target.value))
                return
            setChecks((prevItems)=> [...prevItems,e.target.value])
            return
        }
        setChecks((previtems) => previtems.filter((item) => item !== e.target.value))
            
    }
    const setDataTable = (data) => {
        const RecordsArray = []
        for (const record of data){
            record.Password = record.Password.slice(0,12)
            record.Role = record.Role === "user" ? "Reseller" : "Admin"
            RecordsArray.push(
                <div className='Record'>
                    
                    <span className='RecordElement' style={{borderLeft: '0'}} >
                        <input type='checkbox' className='deleteCheck' id={record.Id} value={record.Id} onClick={UpdateChecks} />
                        {record.Id}
                    </span>
                    <span className='RecordElement'>{record.Username}</span>  
                    <span className='RecordElement' style={{borderRight: '0'}} >{record.Password}</span> 
                    <span className='RecordElement' style={{borderLeft: '0'}} >{record.Role}</span>  
                </div> 
            )
        }      
        setData(RecordsArray)
    }

    const Register = () => {
        // @ts-ignore
        const username = document.getElementById("UserName").value;
        // @ts-ignore
        const password = document.getElementById("password").value;
        // @ts-ignore
        if (username === "" || password === ""){
            setError("Inserisci un username e una password")
            // @ts-ignore
            document.getElementById("Basic").showModal()
            return
        }
        ApiConnect.post("/auth/register",{
            username,
            password,
            Role
        },{headers:{
            "logintoken": localStorage.getItem("logintoken")
        }}).then((res) => {
            setError(res.data.message)
            if (res.data.AuthError)
                return navigate("/Login",{replace: true})
            // @ts-ignore
            document.getElementById("UserName").value = ""
            // @ts-ignore
            document.getElementById("password").value = ""
            // @ts-ignore
            document.getElementById("Basic").showModal()
            fetchTable()
        })

    }

    const DeleteIds = () =>{
        ApiConnect.delete("/reseller/DeleteReseller",{
            headers: {
                "logintoken": localStorage.getItem("logintoken")
            },
            params: {
                Id: checks
            }
        }).then((res) => {
            if (res.data.AuthError)
                return navigate("/Login",{replace: true})
            fetchTable()
            // @ts-ignore
            document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
        })
    }

    return (
        <div className="ColumnFlex" id="RegisterContainer" style={{justifyContent: 'space-between', flexDirection: 'row',width: '100%'}}>
            <Warning  title={<span className="titledialog">Attenzione!</span>} id="Basic">
                <div className="desc">
                    <span style={{fontSize: '20px', padding: '10px'}}>{Error}</span>
                </div>
            </Warning>
            <div className="Panel" style={{width: '40%', height: '75%', rowGap: '20px'}}>
                <div>
                    <h1 className="Title" style={{margin: 0, fontSize: '29px'}} >Registra un nuovo Reseller</h1>
                    <h2 style={{marginTop: '0'}}>Area Reseller</h2>
                </div>
                <div style={{width: '70%'}}>
                    <TextField sx={{...MuiStyles.TextField}} label="Username" fullWidth name="UserName" id="UserName"/>
                </div>
                <div style={{width: '70%'}}>
                    <TextField sx={{...MuiStyles.TextField}} type="password" label="Password" fullWidth name="password" id="password"/> 
                </div>
                <Select 
                sx={{...MuiStyles.Select}} 
                id="Role" 
                name="Role" 
                defaultValue="user"
                onChange={(e) => setRole(e.target.value)}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            ...MuiStyles.MenuItem
                        }
                    }
                }}>
                    <MenuItem value="user">Reseller</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                </Select>
                <button className={"btn"} style={{width: '50%', marginBottom: "20px", marginTop: '0px'}} onClick={Register} ><span style={{fontSize: '30px'}} className={"Title"}>Registra</span></button>
            </div>
            <div className='Panel' style={{width: '50%', height: '75%', rowGap: '20px',marginRight: '5%'}}>
                <h1 className="Title" style={{margin: 0, fontSize: '29px'}} >Lista Account</h1>
                <div className='Table'>
                    <div className='TableHeader'>
                        <span className='TableElementHeader' style={{borderLeft: '0'}} id='Id'>Id</span>
                        <span className='TableElementHeader' id='Username'>Username</span>
                        <span className='TableElementHeader' style={{borderRight: '0'}} id='Password'>Password</span>
                        <span className='TableElementHeader' style={{borderLeft: '0'}} id='Role'>Ruolo</span>
                    </div>
                    <div className='TableContent'>
                        {Data}                     
                    </div>
                    
                </div>
                <div style={{marginBottom: '0px',backgroundColor: 'rgb(210, 4, 45)', alignItems: 'center', width: '55px',height: '55px', display: 'flex', justifyContent: 'center', borderRadius: '15px'}}>
                        <img src={deleteMedia} alt="delete" style={{width: '40px', height: '40px', cursor: 'pointer'}} onClick={DeleteIds} />
                </div>
            </div>
        </div>
    )
}

export default Register;