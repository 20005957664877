import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import MuiStyles from "./MuiStyles";

// Dichiarare che il componente accetta le stesse proprietà di TextField
type CustomTextFieldProps = TextFieldProps & {
  // Aggiungi eventuali props personalizzati qui
};

const CustomTextField: React.FC<CustomTextFieldProps> = ({ sx, ...rest }) => {
  return (
    <TextField
      {...rest} // Passa tutti i props rimanenti al TextField
      sx={{ ...MuiStyles.TextField, ...sx }} // Combina gli stili predefiniti con quelli personalizzati
    />
  );
};

export default CustomTextField;
