// @ts-nocheck
import React, { useEffect } from "react"
import "./Css/Bollettino.css"
import "./Css/ResellerCp.css"
import ApiConnect from "./ApiConnect"
import BackButton from "./BackButton"
import DataViewer from "./DataViewer"
const ResellerCp = () => {
    const [Requests, setRequests] = React.useState([])
    const [RequestsEl, setRequestsEl] = React.useState([])
    const [Details, setDetails] = React.useState({})
    useEffect(() => {
        const fetch = async() => {
            return ApiConnect.get("/reseller/GetResellerContracts", {
                headers: {
                    "logintoken": localStorage.getItem("logintoken")
                }
            }).then(res => {
                setRequests(res.data)
            })
        }
    fetch()
    }, [])

    useEffect(() => {
        const StatusTable = {
            "Espletato" : "rgb(158, 195, 133)",
            "In lavorazione": "rgb(209, 176, 13)",
            "Incompleto" : "rgb(209, 176, 13)",
            "Sospeso" : "rgb(209, 176, 13)",
            "Ko con accodamento" : "rgb(242, 148, 24)",
            "Ko" : "rgb(210, 4, 45)",
        }

        const ShowDialog = async(key) => {
            await setDetails(Requests[key]);
            document.getElementById("DataViewer").showModal()
        }
        const Request =(key,Date,Name,Surname,Status,Note) => {
            return (
                <div className="Request" id={"id" + key} key={key} onClick={() => ShowDialog(key)} >
                    <div className="InternalRequest">
                        <div className="ReqElemnt">
                            <span className="RequestDate">{Date}</span>
                        </div>
                        <div className="ReqElemnt">
                            <span className="RequestName">{Name}</span>
                        </div>
                        <div className="ReqElemnt">
                            <span className="RequestSurname">{Surname}</span>
                        </div>
                        <div className="ReqElemnt" style={{paddingRight: '0'}}>
                            <span className="RequestStatus" style={{color: StatusTable[Status]}}>{Status}</span>
                        </div>
                    </div>
                </div>
            )
            
        }
    
        const RequestHeader = (key,Date,Name,Surname,Status,Note) => {
            
            return (
            <div className="Request" id={"id" + key} key={key} onClick={() => ShowDialog(key)}>
                <div className="InternalRequest">
                    <div className="ReqElemnt">
                        <label className="LabelDate">Data:</label>
                        <span className="RequestDate">{Date}</span>
                    </div>
                    <div className="ReqElemnt">
                        <label className="LabelDate">Nome:</label>
                        <span className="RequestName">{Name}</span>
                    </div>
                    <div className="ReqElemnt">
                        <label className="LabelDate">Cognome:</label>
                        <span className="RequestSurname">{Surname}</span>
                    </div>
                    <div className="ReqElemnt">
                        <label className="LabelDate">Stato:</label>
                        <span className="RequestStatus" style={{fontWeight: '600',color: StatusTable[Status]}}>{Status}</span>
                    </div>
                </div>
            </div>)
        }
        const arr = []
        Requests.forEach((element, index) => {
            if (index === 0)
                arr.push(
                    RequestHeader(index, element.Date, element.Nome, element.Cognome, element.Status, element.Note)
                )
            else 
                arr.push(
                    Request(index, element.Date, element.Nome, element.Cognome, element.Status, element.Note)
                )
        })
        setRequestsEl(arr)
    }, [Requests])


    return (
        <div className="App">
            <BackButton id={"AdminBackButton"} />
            <DataViewer Data={Details}/>
            <span className="Title">Reseller Control Panel</span>
            <div className="panel" id="ResellerContainer" style={{width: '60%'}} >
                <div style={{ width: '100%' }}>
                    <span className="SubTitle">Richieste In Sospeso</span>
                    <div className="InternalPanel" style={{ rowGap: '5px',minHeight: '45vh',maxHeight: '45vh', overflowY: 'auto'  }}>
                        {RequestsEl}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ResellerCp